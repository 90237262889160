import React, { useContext } from "react"
import { Layout } from "../layouts"
import "./404.scss"
import mainImage from "../assets/images/404-page/404-img.svg"
import dotsImg from "../assets/images/404-page/dots.svg"
import SEO from "../components/Seo"
import { translateText } from "../utils/translateText"
import { LANGUAGES } from "../constants"
import { LanguageContext } from "../contexts/languageContext"

const NotFoundData = () => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  return (
    <div className="not-found-page">
      <div className="content-wrapper">
        <h1>
          {translateText(
            "The page you were looking for was not found",
            currentLanguage.fullTitle,
            googleSheetData
          )}
        </h1>
        <p>
          {translateText(
            "Return to the",
            currentLanguage.fullTitle,
            googleSheetData
          )}
          <a className="link" href="/">
            {translateText(
              "homepage",
              currentLanguage.fullTitle,
              googleSheetData
            )}
            →
          </a>
        </p>
      </div>
      <div className="images-wrapper">
        <img src={mainImage} alt="404" width="489" height="187" />
        <img src={dotsImg} alt="dots" width="487" height="95" />
      </div>
    </div>
  )
}

const NotFoundPage = ({ pageContext: { companies }, location }) => (
  <Layout location={location} lang={LANGUAGES.ENGLISH.label.toLowerCase()} companies={companies}>
    <SEO
      title="404"
      canonical={location.pathname}
      data={{ metadescription: "404-page" }}
    />
    <NotFoundData />
  </Layout>
)

export default NotFoundPage
